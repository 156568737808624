import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import specialFormatter from '../utility/special-formatter'

class Team extends React.Component {
  render() {
    const site = get(this, 'props.data.contentfulSite')
    const team = get(this, 'props.data.allContentfulPerson.edges')
    return (
      <Layout>
        <div className="team-title" style={{ background: `url(${site.teamImage.fixed.src}) no-repeat center #131012` }} Tag="div">
          <div className="container">
            <h1>The Supergiant Team</h1>
          </div>
        </div>

        <div className="container">
          <ul className="team-list">
            {team.map(({ node }) => {
              return (
                <li key={node.name}>
                  <div className="head">
                    <Img fixed={node.image.fixed} className="image" />
                    <h3>{node.name}</h3>
                    <p>{node.title}</p>
                  </div>
                  <div className="about">
                    <div
                      className="about"
                      dangerouslySetInnerHTML={{
                        __html: specialFormatter(node.about.childContentfulRichText.html),
                      }}
                    />
                  </div>

                  <strong>Favorite games</strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: specialFormatter(node.favoriteGames.childContentfulRichText.html),
                    }}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </Layout>
    )
  }
}

export default Team

export const pageQuery = graphql`
  query TeamPage {
    allContentfulPerson(filter: {hideFromTeamPage: {eq: false}}, sort: { fields: [order], order: DESC }) {
      edges {
        node {
          name
          twitter
          title
          order
          image {
            fixed(width: 124, height: 124, quality: 70, resizingBehavior: FILL) {
              ...GatsbyContentfulFixed
            }
          }
          about {
            childContentfulRichText {
              html
            }
          }
          favoriteGames {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
    contentfulSite {
      teamImage {
        fixed(width: 1200, height: 380, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFixed
        }
      }
    }
  }
`
